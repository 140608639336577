function displayDatatable() {
    let paths = window.location.toString().split("/");
    let file_name = paths[paths.length - 1] + "_" + moment().format('YYYY-MM-DD');

    for (let step = 0; step < 5; step++) {
        let datatable_table_id = step > 0 ? "#datatable_" + step : "#datatable"

        let table = $(datatable_table_id).DataTable({
            ordering: false,
            aLengthMenu: [
                [10, 50, 100, 200, -1],
                [10, 50, 100, 200, "All"]
            ],
            iDisplayLength: 10,
            buttons: [
                {extend: 'copyHtml5', title: file_name, className: 'refresh_button btn-sm'},
                {extend: 'excelHtml5', title: file_name, className: 'refresh_button btn-sm'},
                {extend: 'csvHtml5', title: file_name, className: 'refresh_button btn-sm'},
                {extend: 'pdfHtml5', title: file_name, className: 'refresh_button btn-sm'},
            ]
        });

        table.buttons().container()
            .appendTo(datatable_table_id + '_length.dataTables_length');

        $('#distributor').on('change', function () {
            table.search(this.value).draw();
        })

        $('#statuses_filter').on('change', function () {
            table.search(this.value).draw();
        })
    }
}

// Prevent code to be rerun twice during page reload
var ready
ready = function () {
    if ($('body').attr('data-loaded') === 'T') {
        return
    }

    // Your code below
    displayDatatable()
    if ($(window).width() > 768) $("li.nav-item.active div").show()
    return $('body').attr('data-loaded', 'T')
}

$(document).ready(ready)
$(document).on('turbolinks:load', ready)