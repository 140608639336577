// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import "bootstrap"
import "../stylesheets/application"
import "jquery.easing"
import "datatables.net-bs5"
import "datatables.net-buttons-bs5"
import "datatables.net-buttons/js/buttons.flash"
import jsZip from "jszip"
import "pdfmake"
import "./vfs_fonts"
import "datatables.net-buttons/js/buttons.html5"
import "datatables.net-buttons/js/buttons.print"
import "datatables.net-buttons/js/buttons.colVis"
import "daterangepicker"
import moment from 'moment'
import "./advance_datatables"
import "./scripts"

const feather = require('feather-icons')
window.moment = moment;
window.JSZip = jsZip;

function enableTooltips() {
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover()
}

document.addEventListener("turbolinks:load", () => {
    feather.replace();
    enableTooltips()

    window.setTimeout(function () {
        $(".alert-messages").fadeTo(2000, 0).slideUp('slow', function () {
            $(this).remove();
        });
    }, 2000);
})

// enableTooltips()
